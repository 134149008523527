<template>
  <span>
    <div v-if="isVimeo">
      <ask-consent-overlay v-if="!consentForVimeo" type="vimeo" :contentPadding="100"></ask-consent-overlay>
      <div v-else class="video-module embed-responsive embed-responsive-16by9" v-html="data.movieLink"></div>
    </div>
    <div v-if="isYoutube">
      <ask-consent-overlay v-if="!consentForYoutube" type="youtube" :contentPadding="100"></ask-consent-overlay>
      <div v-else class="video-module embed-responsive embed-responsive-16by9" v-html="data.movieLink"></div>
    </div>
    <div v-if="isTwentythree">
      <ask-consent-overlay v-if="!consentForTwentyThree" type="twentyThree" :contentPadding="100"></ask-consent-overlay>
      <div v-else style="width:100%; height:0; position: relative; padding-bottom:56.24999999296875%">
        <iframe
          title="Video Player"
          :src="data.movieLink"
          style="width:100%; height:100%; position: absolute; top: 0; left: 0;"
          width="100%"
          height="100%"
          frameborder="0"
          border="0"
          scrolling="no"
          allowfullscreen
          allow="autoplay; fullscreen"
        ></iframe>
      </div>
    </div>
    <div v-if="isVideoTool">
      <ask-consent-overlay v-if="!consentForVideoTool" type="videoTool" :contentPadding="100"></ask-consent-overlay>
      <div v-else style="width:100%; height:0; position: relative; padding-bottom:56.24999999296875%">
        <iframe
          title="Video Player"
          :src="data.movieLink"
          style="width:100%; height:100%; position: absolute; top: 0; left: 0;"
          width="100%"
          height="100%"
          frameborder="0"
          border="0"
          scrolling="no"
          allowfullscreen
          allow="autoplay; fullscreen"
        ></iframe>
      </div>
    </div>
    <div v-if="isDreamBroker">
      <ask-consent-overlay v-if="!consentForDreamBroker" type="dreamBroker" :contentPadding="100"></ask-consent-overlay>
      <div v-else style="width:100%; height:0; position: relative; padding-bottom:56.24999999296875%">
        <iframe
          title="Video Player"
          :src="data.movieLink"
          style="width:100%; height:100%; position: absolute; top: 0; left: 0;"
          width="100%"
          height="100%"
          frameborder="0"
          border="0"
          scrolling="no"
          allowfullscreen
          allow="autoplay; fullscreen"
        ></iframe>
      </div>
    </div>
  </span>
</template>

<script>
import moduleMixin from '../../mixins/module';
import AskConsentOverlay from '@/app/components/ui/consentOverlay/AskConsentOverlay.vue';
import { mapGetters } from 'vuex';

export default {
  components: { AskConsentOverlay },
  mixins: [moduleMixin],
  computed: {
    ...mapGetters('settings', [
      'consentForYoutube',
      'consentForVimeo',
      'consentForTwentyThree',
      'consentForVideoTool',
      'consentForDreamBroker'
    ]),
    isVimeo() {
      return this.data.movieLink.includes('vimeo');
    },
    isYoutube() {
      return this.data.movieLink.includes('youtu');
    },
    isTwentythree() {
      return this.data.movieLink.includes('twentythree');
    },
    isVideoTool() {
      return this.data.movieLink.includes('videotool');
    },
    isDreamBroker() {
      return this.data.movieLink.includes('dreambroker');
    }
  }
};
</script>

<style lang="scss">
@import '@shared/styles/_helpers.scss';
.video-module {
  border-radius: $general-border-radius;
  iframe {
    overflow: hidden;
  }
}
</style>
